import * as React from "react";
import { graphql } from "gatsby";
import { Link, withPrefix } from 'gatsby';
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CDefinition, CBtnList
} from "../../components/_index"
import { size } from "lodash";
import { setLang } from "../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap size="small">
          <h1 className="c_headingLv2">ソーシャルメディアポリシー・利用規約</h1>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">三菱地所グループ ソーシャルメディアポリシー</h2>
            <p><Link to="https://www.mec.co.jp/socialmedia/" target="_blank" rel="noopner noreferrer">三菱地所グループソーシャルメディアポリシー</Link></p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">三菱地所ホテルズ＆リゾーツ株式会社 ソーシャルメディアサービス利用規約</h2>
            <p className="u_mb20">1. 本規約は、三菱地所グループ（三菱地所株式会社及び三菱地所グループ各社。以下、「当グループ」という）の三菱地所ホテルズ＆リゾーツ株式会社（以下、「当社」という）及び当社の許諾を得て当社の商標が使用されているホテル（当社が経営・運営するホテルと併せて、以下、「ロイヤルパークホテルズ 」という）が運営するソーシャルメディアサービスのアカウント（以下、「本サービス」という）をユーザー（以下、「利用者」という）が利用する際の規則を定めるものです。</p>
            <p className="u_mb20">2. 本サービスの利用者は、利用に際し、以下の行為（そのおそれのある行為を含みます）を行わないものとします。<br />
            （1） 当グループ、他の利用者その他の第三者の権利・利益を侵害する行為<br />
            （2） 当グループ、他の利用者その他の第三者を誹謗中傷し、侮辱し、名誉、信用、プライバシー等を棄損し（当社、他の利用者その他の第三者のメールアドレス、電話番号、住所等の個人の特定につながる情報を開示する行為を含む）、または業務を妨害する行為<br />
            （3） 公職選挙法に違反する行為<br />
            （4） 宗教団体その他の団体・組織への加入を勧誘する行為<br />
            （5） 出資、寄付、資金提供または物品若しくはサービスの購入等を勧誘する行為<br />
            （6）当社が不適切と判断する他のウェブサイトを紹介し若しくはその閲覧を勧誘する行為または本サービスでのコメント、リプライ、チャット等をコンテンツのダウンロードの場として利用する行為<br />
            （7） 本サービスを通じて得た情報を営利目的に流用する行為<br />
            （8） 本サービスを利用 して当社、他の利用者その他の第三者に対し、 コンピューター のソフト・ハードの正常な機能を阻害するウイルス等の有害なプログラムまたはファイル等を発信する行為<br />
            （9） 本サービスに掲載する正当な権限を有しない情報・コンテンツを掲載する行為<br />
            （10） 当グループ、他の利用者その他の第三者による本サービスの提供及び利用を阻害する行為<br />
            （11） 本サービスに対しハッキング等の不正行為によりアクセスする行為及び本サービスの全部または一部を監視若しくは複製する行為<br />
            （12） その他、利用規約、公序良俗、法令若しくは刑罰法規に違反し、またはその他当社が不適切と判断する行為</p>
            <p className="u_mb20">3. 本サービスの利用者が本規約に違反した場合、当社は、当該利用者による書き込みを 削除し、または当該利用者による本サービスの利用を制限することができ、利用者はこれに異議を唱えないものとします。</p>
            <p className="u_mb20">4. 本サービスの利用者が本規約に違反し、または本サービスの利用に関連して当社、他の利用者その他の第三者に有形無形の損害を与えた場合、当該利用者はこれを自己の責任と負担において賠償し、その他の解決をはかるものとし、当社は一切の責任と負担を負わないものとします。</p>
            <p className="u_mb20">5. 当社は、本サービスを通じて利用者により提供される情報について 、その内容を保証または認可したものではありません。従って、その内容を信用したことにより利用者に損害等が生じた場合にも当社は一切責任を負いません。</p>
            <p className="u_mb20">6. 利用者が本サービスを通じて掲載した情報についての著作権は全て当社に帰属し、また利用者はこれについての著作者人格権を行使しないものとします。当該権利の帰属及び放棄について利用者には対価を請求する権利はありません。</p>
            <p className="u_mb20">7. 当社は本サービスの利用者の同意を得ることなく本利用規約の内容を変更することができます。この場合、変更後の本規約は当社がウェブサイトヘの掲載その他の方法 により公表した時点で当然に効力が生じ、以降本サービスの利用者は変更後の本規約の適用を受けるものとします。</p>
            <p className="u_mb20">8. 本サービスの利用及び本規約に伴う紛争については、東京地方裁判所が第一審の専属管轄権を有するものとします。</p>
            <p className="u_mb20 u_tar">2021年10月1日制定<br />三菱地所ホテルズ＆リゾーツ株式会社<br />運営推進部</p>
          </section>

        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`